@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');


/*Default CSS*/
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body{
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #f6f5f4 !important;
  color: #1d1e22 !important;
  font-family: 'Crimson Text', sans-serif !important;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
  line-height: normal;
  font-weight: 600 !important;
}

p{
  font-size: 18px;
}

section h1{
    font-size: 52px;
}

/** Navbar CSS*/
nav.navbar {
  position: fixed;
  background-color: #1d1e22;
  width: 100%;
  top: 0;
  z-index: 999;
  padding: 0 0;
}

nav.navbar a.navbar-brand {
    width: 24%;
    font-size: 32px;
    margin-right: 40px;
    color: #f6f5f4 !important;
}
nav.navbar .navbar-nav .nav-link.navbar-link{
  font-weight: 400;
  color: #f6f5f4 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}

nav.navbar-toggler{
  color: #f6f5f4 !important;
  outline-color: #f6f5f4 !important;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 100px 0 40px 0;
  height: 100%;
}
.banner h1 {
  font-size: 62px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  font-weight: 600;
  text-align: center;
}


@media only screen and (max-width: 1200px) {
  .banner h1{
    font-size: 48px;
  }
}

@media only screen and (max-width: 993px) {
  .banner h1{
    font-size: 40px;
  }
}

.carousel{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/************ AboutMe Css ************/
.rolam-section {
  padding: 0 0 50px 0;
  position: relative;
}

.rolam-bx {
  background-color: #393f4d;
  color: #f6f5f4;
  border-radius: 10px;
  text-align: center;
  padding: 30px 50px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.rolam-section p{
  max-width: 700px;
  text-align: justify;
  vertical-align: top;
  margin-top: 20px;
}

.rolam-section p.col{
  padding: 20px 30px;
  margin: 0;
}

.rolam-text{
    display: flex;
    justify-content: center;
    align-items: center;
}

.rolam-bx h3{
  font-size: 20px;
  margin-bottom: 4px;
}

.rolam-row{
  justify-content: space-evenly;
}

/************ Works Css ************/

.work-row{
  justify-content: space-evenly;
  row-gap: 40px;
}

.work-bx {
  background-color: #393f4d;
  color: #f6f5f4;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  text-align: center;
  padding: 30px 50px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.work-title{
  margin-bottom: 20px;
}

.work-bx img{
  border-radius: 4px;
}

.work-btn{
  margin-top: 20px;
  font-weight: 600 !important;
  padding: 10px 40px !important;
  background-color: #1d1e22 !important;
  color: #f6f5f4 !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.work-btn:hover{
  scale: 1.1;
}


/************ Contact Css ************/

.contact {
  padding: 60px 10px 100px 10px;
}

.contact-box{
  background-color: #393f4d !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  padding: 30px 50px;
  margin-top: 20px !important;
  margin-bottom: 20px;
}

.contact h3 {
  color: #f6f5f4;
	font-size: 32px;
	font-weight: 700;
  margin-bottom: 10px;
}

.contact h4 {
  color: #f6f5f4;
	font-size: 18px;
	font-weight: 600;
  margin-bottom: 30px;
}

.contact-elements a{
  text-decoration: none;
  color: #f6f5f4 !important;
  margin-left: 10px;
}
.contact-elements h3:hover{
  scale: 1.1 !important;
}

.contact-elements h3{
  margin: 40px 10px 10px  !important;
}

.form-header{
  margin-top: 30px;
}

.contact form input, .contact form textarea {
  width: 100%;
  border: 1px solid 1d1e22;
  border-radius: 20px;
  color: #393f4d;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #393f4d;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  border-radius: 10px !important;
  font-weight: 700;
  color: #f6f5f4;
  background-color: #1d1e22;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #f6f5f4;
}
.contact form button::before {
  content: "";
  background: #393f4d;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Subpage Css ************/

.subpage {
  margin-top: 0;
  padding: 100px 0 100px 0;
  height: 100%;
}

.subpage h1{
  margin-bottom: 30px;
}


/************ Gallery Css ************/

.masonry-img{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  margin-bottom: 30px !important;
  cursor: pointer;
}

.masonry-img:hover{
  transform: scale(1.05);
}

.image-grid img{
  pointer-events: none;
  border-radius: 10px;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}


.modal-img img{
  pointer-events: none;
  border-radius: 4px;
  height: 100% !important;
}

/************ Footer Css ************/

.footer{
  background-color: #1d1e22;
  padding: 60px;
  color: #f6f5f4;
  text-align: center;
}

.footer h4{
  font-size: 32px;
}

.social-icon svg{
  margin: 0 10px;
  cursor: pointer;
}

.social-icon svg:hover{
  scale: 1.2;
}

.footer p{
  margin: 10px;
}